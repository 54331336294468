import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router'; // https://stackoverflow.com/questions/69203538/useeffect-dependencies-when-using-nextjs-router
import qs from 'qs';

import MiragePage from 'Components/Mirage/MiragePage';
import { logger } from 'Services/logger/logger';
import { isProd, isSSR } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

const getPath = ({ pathname, query }) => `${pathname}${qs.stringify(query, { addQueryPrefix: true })}`;

const MirageWrapper = ({ children }) => {
	const isMirageServerEnabled = useFeatureToggle(FEATURES.mirageServer);
	// If cypress tests are checking the url changes, don't allow mirage server booting to hijack the url
	const isMirageSoftLaunchEnabled = useFeatureToggle(FEATURES.mirageServerSoftLaunch);
	const isAnalyticsEventLoggerEnabled = useFeatureToggle(FEATURES.analyticsEventLogger);
	const [isHydratedWithMirage, setIsHydratedWithMirage] = useState(false);
	const isMirageEnabled = !(isSSR() || isProd || !isMirageServerEnabled);

	useEffect(() => {
		if (!isMirageServerEnabled) {
			setIsHydratedWithMirage(true);
			return;
		}

		(async () => {
			window.mirageServer?.shutdown();
			const createServer = await import('Mirage/server')
				.then((module) => module.default)
				.catch((error) =>
					logger.error({
						error,
						message: error?.message || 'There was an issue with loading Mirage server',
						scope: 'mirage/server',
					}),
				);

			const { Cypress } = window;
			const mirageSettings = Cypress ? { state: 'cypress', timing: 0 } : { state: 'default' };
			window.mirageServer = createServer(mirageSettings);
			if (!Cypress) {
				window.mirageServer.logging = !isAnalyticsEventLoggerEnabled;
			}

			Router.replace(getPath(Router), Router.asPath, { shallow: isMirageSoftLaunchEnabled });
			setIsHydratedWithMirage(true);
		})();
	}, [isAnalyticsEventLoggerEnabled, isMirageServerEnabled, isMirageSoftLaunchEnabled]);

	useEffect(() => {
		if (window.mirageServer?.shutdown && !isMirageServerEnabled) {
			window.mirageServer.shutdown();
			Router.replace(getPath(Router), Router.asPath);
		}
	}, [isMirageServerEnabled]);

	if (!isMirageEnabled || !isMirageServerEnabled) {
		return children;
	}

	return isHydratedWithMirage ? children : <MiragePage />;
};

MirageWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

export default MirageWrapper;
